import React from 'react';

import { showModal, hideModal, getModals } from '../redux/operations';
import MessageModal from '../Modules/Modals/MessageModal';
import Langs from '../Langs';
import { MODAL_TYPES } from '../Constants/index';

const newVersionModal = () => {
    const modals = getModals();
    if (modals.length && modals.find(modal => modal?.id === MODAL_TYPES.NEW_VERSION)) return;

    const txt = Langs[global.lng];

    const handleConfirm = () => {
        window.location.reload(true);
    };

    const onReject = () => {
        setTimeout(() => {
            newVersionModal();
        }, 60000);
        hideModal();
    };

    showModal({
        id: MODAL_TYPES.NEW_VERSION,
        title: txt.titles.newVersion,
        onReject,
        body: props => (
            <MessageModal
                {...props}
                message={txt.texts.newVersion}
                confirmButtonText={txt.buttons.loadNewVersion}
                rejectButtonText={txt.buttons.later}
                onConfirm={handleConfirm}
                onClose={onReject}
            />
        )
    });
};

const connectWebSocket = () => {
    const ws = new WebSocket(process.env.WEBSOCKET_PATH);
    ws.onmessage = e => {
        if (!JSON.parse(process.env.IS_DEVELOPMENT) && +e.data > process.env.VERSION) newVersionModal();
    };

    ws.onclose = () => {
        // console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(() => {
            connectWebSocket();
        }, 60000);
    };

    ws.onerror = err => {
        // eslint-disable-next-line no-console
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        ws.close();
    };
};

export default connectWebSocket;
